
import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, Dropdown, List, Label, Popup, Checkbox, Segment, ButtonGroup, ButtonOr, Menu, MenuItem } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API_URL } from '../App.js';
import axios from 'axios';
// import {retrieveSubjectObject, nounoptions1, nounoptions2, nounoptions3, nounoptionsmodalis, options1, options2, options3, verbPostbases, nounPostbases} from './constants/constants.js'
import {dialogues} from './constants/dialogues.js';
import {postbasesEndingsUnderlying} from './constants/underlying.js'
// import palette from 'google-palette';
// import shuffle from 'shuffle-array';
import { TagColors } from './HomeHelper.js';
import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
// import L from "leaflet";
// import {geoJSONMap} from "./constants/placenames20.js";
// import 'leaflet/dist/leaflet.css';
// import 'leaflet.zoomhome/dist/leaflet.zoomhome.js';
// // import icon from 'leaflet/dist/images/marker-icon.png';
// // import iconShadow from 'leaflet/dist/images/marker-shadow.png';
// import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
// import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";
// // import { library } from '@fortawesome/fontawesome-svg-core'
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// // import { faCoffee } from '@fortawesome/free-solid-svg-icons'
// // import { dom } from '@fortawesome/fontawesome-svg-core'
// // dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
// import { library, dom } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { customFontFam3, customTitleFontFam3, customFirefoxTitleFontFam } from './constants/constants.js';
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// library.add(fas) 
// dom.i2svg() 

ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;

let customFontFam = customFontFam3
let customTitleFontFam = customTitleFontFam3
let customFontFam2 = {fontFamily:customTitleFontFam3}
let letterbutton = {fontFamily:customFontFam3,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
const isFirefox = typeof InstallTrigger !== 'undefined';

if (isFirefox) {
  customFontFam = customFirefoxTitleFontFam
  customTitleFontFam = customFirefoxTitleFontFam
  customFontFam2 = {fontFamily:customFirefoxTitleFontFam}
  letterbutton = {fontFamily:customFirefoxTitleFontFam,paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}
}

let underlineColor = '#b5b5b5'

const options = [
  { key: 's', text: 'Tlingit only', value: 0 },
  { key: 'se', text: 'Tlingit with English', value: 1 },
  { key: 'b', text: 'Book', value: 2 },
]

class Conversations extends Component {
	constructor(props) {
		// console.log(props)
		super(props);

    let currentChapter = decodeURI(props.match.params.num)
    if (currentChapter == null || !(currentChapter in dialogues)) {
      currentChapter = '1'
      this.props.history.push('/dialogues/1')            
    }

		this.state = {
      parseIndex:0,
      presentation:5,
      chapter:currentChapter,
      // activeChapter:'1',
      sortValue:0,
			// map:undefined,
			// markersById:{},
			// overlayMaps:{},
   //    sortValue:0,
			// history: props.location.state === undefined ? [] : props.location.state.history,
			// from: props.location.state === undefined ? '' : props.location.state.from,
		}	   
		scroll.scrollTo(0);

	}

	repeatAudio(audio, event, data) {
	  if (!this.state.playingAudio) {

	    let sound = new Audio(API_URL + "/tlingitaudiolibrary/" +  audio);
	    this.setState({playingAudio: true});

	    sound.play()

	    sound.onended=()=>{
	      this.setState({playingAudio: false});
	    }
	  }
	}

// <link rel="preconnect" href="https://fonts.googleapis.com">
// <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
// <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&amp;display=swap" rel="stylesheet">
// [leaflet-map address="Juneau, Alaska" zoom="10" height="700" tileurl=https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg zoomcontrol dragging scrollwheel gesture doubleclickzoom]
// [leaflet-geojson src="https://gist.githubusercontent.com/lonnystrunk/fd99e52cfb86c1ebe207506bad898119/raw/fffe18df77df9c39af18a272d708b905571d7c92/placenames.geojson"]
	// <span style="font-family:'Raleway'"><b>{name}</b> #{id}
	// {translation}
	// {location}</span>
// [/leaflet-geojson]
// [geojsonmarker svg property=marker-color 
	// icondefault=#B5B5B5 
	// iconprops="#0056d6,#6EE5FF,#2EA524,#890101,#6F4413,#7c7c7c" 
	// shape=circle 
	// icon=fa-solid 
	// prefix=fa-circle 
	// disableClusteringAtZoom=4  
	// values="#0056d6,#00c7fc,#4f7a28,#555555,#d95000,#b5b5b5" 
	// groups="Water,Ice,Land,Settlement,Trail,Unsure" 
	// visible=1,1,1,1,1,0]
// [fullscreen][zoomhomemap !fit]

	componentDidMount() {
	
	}

  processStyledText = (sentence) => {
      sentence = sentence.replaceAll("???","...")
      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("«",'"').replaceAll("»",'"')

      return <span>{sentence}</span>


    // sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("«","").replaceAll("»","")
    // let matches = sentence.match(/⎡.*?⎤/g)
    // let matches1 = sentence.match(/{.*?}/g)
    // let matches2 = sentence.match(/_.*?_/g)
    // // console.log(matches2)
    // if (matches !== null || matches1 !== null || matches2 !== null) {
    //   if (matches !== null) {
    //     matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
    //   }
    //   if (matches1 !== null) {
    //     matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
    //   }
    //   if (matches2 !== null) {
    //     matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
    //   }
    //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
    // } else {
    //   return <span>{sentence}</span>
    // }


  }

  handleChapterClick = (e, {value}, chapter) => {
    // console.log(value, chapter)
    if (chapter == undefined) {
      chapter = value
    } else if (typeof chapter === "object") {
      chapter = e
    }
    this.props.history.push('/dialogues/'+chapter)
    this.setState({chapter:chapter})
    scroll.scrollTo(0);
  }

  handleSortChange = (e, {value}) => {
    if (value === 0 && this.state.sortValue !== 0) {
      this.setState({sortValue: 0})
    } else if (value === 1 && this.state.sortValue !== 1) {
      this.setState({sortValue: 1})
    } else if (value === 2 && this.state.sortValue !== 2) {
      this.setState({sortValue: 2})
    }
  } 


	render() {

    let chapter = this.state.chapter
    let fontSize = '20px'
    let innerWidthSetting = 600
    if (window.innerWidth < innerWidthSetting) {
      fontSize = '16px'
    }
    // console.log(window.innerWidth)
    let chapterOptions = []
    Object.keys(dialogues).map((k)=>{chapterOptions.push({ key: k, text: k+': '+dialogues[k]['title'], value: k })})

		return (
			<div className="app" style={{fontFamily:customFontFam}}>
          <Link to={{pathname: "/"}}>
          <Icon circular style={{marginLeft:10,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
          </Link>         

      <Grid style={{marginTop:10}}>
        <Grid.Row>
        {window.innerWidth < innerWidthSetting ?
          null
          :
          <Grid.Column width={4}>
            <Menu fluid vertical tabular>
              {Object.keys(dialogues).map((k)=>
                <MenuItem
                  active={chapter === k}
                  value={k}
                  onClick={this.handleChapterClick}
                >{k+': '+dialogues[k]['title']}</MenuItem>
              )}
            </Menu>
          </Grid.Column>
        }

        <Grid.Column stretched width={(window.innerWidth < innerWidthSetting ? 16:12)}>
          <Segment>

        <Grid textAlign='center'>
        <Grid.Row style={{padding:0,margin:0,}}>
          <Grid.Column style={{ padding: 0, margin: 0 }} textAlign='left'>
            <div>

              <div>

                  <div>

                    <div style={{display:'flex',backgroundColor:'#F3F3EC',flexDirection:(window.innerWidth < innerWidthSetting ? 'column':'row'),justifyContent:'space-between',alignItems:'center'}}>
                      
                      <div className='hierarchymain' style={{flex:2,height:'100%',padding:(window.innerWidth < innerWidthSetting ? '5px': '15px 0px'),marginLeft:8}}>
                          {window.innerWidth < innerWidthSetting ? 
                            <Dropdown fluid selection selectOnBlur={false} options={chapterOptions} text={chapterOptions[parseInt(this.state.chapter)-1].text} style={{maxWidth:'350px', backgroundColor:'#fdfff8', fontFamily:customFontFam,fontSize:'15px',fontWeight:400,color:'#000000DE'}}>
                              <Dropdown.Menu style={{maxHeight:'170px'}}>
                                {chapterOptions.map((k,kindex)=>{
                                  return <Dropdown.Item onClick={this.handleChapterClick.bind(this,k.key)} active={parseInt(this.state.chapter)-1 == kindex}>{k.text}</Dropdown.Item>
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                            :
                            <span className='span2'>{'Chapter '+this.state.chapter+': '+dialogues[this.state.chapter]['title']}</span>
                          }
                      </div>
                       

                      <div className='hierarchymain' style={{flex:1,justifyContent:'flex-end',height:'100%',padding:'5px 0px',marginRight:15}}>
                      <span className='span2' style={{marginLeft:15,fontSize:(window.innerWidth < innerWidthSetting ? '15px':'16px')}}>Format:</span>
                      <Dropdown options={options} value={this.state.sortValue} onChange={this.handleSortChange} pointing='top right' style={{fontFamily:customFontFam,fontSize:(window.innerWidth < innerWidthSetting ? '15px':'16px'),fontWeight:400,color:'#000000DE',padding:5,borderRadius:5,marginLeft:5}} />
                      </div>

                    </div>

                    {this.state.sortValue != 2 ?

                        <div style={{margin:'0px',padding:0}}>

                        {dialogues[this.state.chapter]['content'].map((e,eindex)=>{

                          if (e['type'] === 'dia') {

                            return <div style={{margin:20,fontSize:fontSize,lineHeight:'34px'}}>

                            {e['tlingit'].map((f,findex)=> {
                              return <span style={{paddingLeft:(e['ids'][findex][2] === "R" ? '20px':'0px')}}> 
                                {findex>0 ? <span style={{fontStyle:'italic', marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'6px',fontSize:fontSize, color:'grey', borderBottom:'2px dotted white'}}>{'or'}</span>:null}
                                
{/*                                {e['ids'][findex][2] === "R" ? <span>&emsp;</span>:null} 
*/}                                {e['ids'][findex][0] !== "" ? <Icon circular onClick={()=>this.repeatAudio('dialogues-'+e['ids'][findex][0]+'.mp3')} style={{marginRight:'10px',fontSize:'17px',color:(e['ids'][findex][1] === 'N' ? '#7e6464':'#5b6c85'),cursor:'pointer'}} name='volume up' />:null}
                                {f.map((t,tindex)=>{
                                let entries = [...t[1],...t[2]]
                                let maxLength = (entries.length % 2 === 0 ? entries.length/2 : (entries.length+1)/2)
                                if (entries.length === 0) {

                                  return <span><span style={{ marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'3px',fontSize:fontSize, borderBottom:'2px dotted white'}}>
                                    {this.processStyledText(t[0])}
                                    </span>
                                    {' '}
                                  </span>

                                } else {

                                  return <Popup on='click' position={window.innerWidth < innerWidthSetting ? 'bottom left' : 'bottom'} onClose={()=>{this.setState({parseIndex:0})}} content={
                                    <div style={{fontFamily:customFontFam, fontSize:'16px'}}>
                                      <div style={{textAlign:'center',fontStyle:'italic',color:'#aeaeae',margin:3}}>Possible Entries</div>
                                      {entries.map((k,kindex)=>{
                                        if (kindex === this.state.parseIndex*2 || kindex === this.state.parseIndex*2+1) {
                                        return <div style={{marginTop:15,marginBottom:10}}>
                                            <div style={{display:'flex',alignItems:'center',flexDirection:'row',fontWeight:'500'}}>
                                                  {k['url'].length === 0 ?
                                                    <span style={{whiteSpace:'nowrap'}}>{k['tlingit']}</span>
                                                    :
                                                    <a style={{whiteSpace:'nowrap'}} href={"https://www.haashuka.com/"+k['url']} target="_blank">{k['tlingit']}</a>
                                                  }
                                                  {k['tags'].map((t,tindex)=>
                                                      (tindex === 0 && t.length != 0 ?
                                                  <span style={{display:'flex',marginLeft:'12px'}}>
                                                    <TagColors homeMode={0} tags={t} width={window.innerWidth} />
                                                  </span>
                                                  :
                                                  null
                                                  )
                                                )}
                                            </div>
                                            <div style={{fontStyle:'italic',marginTop:'5px'}}>{k['english']}</div>
                                        </div>
                                        }})}

                                      {this.state.parseIndex+1 < maxLength+1 && maxLength > 1 ?
                                        <div style={{display:'flex',justifyContent:'center',marginTop:'12px'}}>
                                        <Icon disabled={this.state.parseIndex === 0} circular onClick={() => this.setState({parseIndex: this.state.parseIndex-1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron left' />
                                        <Label basic circular style={{width:30,color:'#aeaeae',fontWeight:500,height:30,fontSize:14,}}>
                                          {this.state.parseIndex + 1}
                                        </Label>
                                        <Icon disabled={this.state.parseIndex+1 === maxLength} circular onClick={() => this.setState({parseIndex: this.state.parseIndex+1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron right' />
                                        </div>  
                                        :
                                        null
                                      }      
                
                                    </div>
                                  } trigger={<span>
                                    <span style={{cursor:'pointer', marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'3px',fontSize:fontSize, borderBottom:'2px dotted '+underlineColor}}>
                                      {this.processStyledText(t[0])}
                                    </span>
                                    {' '}
                                    </span>
                                  } />

                                }


                              })}
                                  {e['extra'][findex] !== "" ?
                                    <Popup hideOnScroll on='click' content={
                                      <div style={{fontFamily:customFontFam, fontSize:'14px'}}>
                                        <span>{e['extra'][findex]}</span>
                                      </div>
                                    } trigger={
                                      <Icon style={{cursor:'pointer',color:'#e5e5e5',fontSize:'18px',marginLeft:'2px'}} name='info circle'>{'\n'}</Icon>
                                    } />
                                    :
                                    null
                                  }

                              {this.state.sortValue === 1 ?
                                <div style={{fontSize:'16px',marginLeft:(e['ids'][findex][0] !== "" ? '43px':'0px'),paddingLeft:(e['ids'][findex][2] === "R" ? '20px':'0px')}}>{e['english']}</div>
                                :
                                null
                              }

                              </span>


                            })} 




                            </div>

                          } else if (e['type'] === 'text') {
                            return <div style={{fontSize:'16px',padding:"25px 45px",fontStyle:'italic',lineHeight:"22px"}}>{e['english']}</div>
                          } else if (e['type'] === '!ex') {
                            return <div style={{display:'flex',flexDirection:'column',fontSize:'16px',padding:"10px 15px",marginLeft:"48px",lineHeight:"22px",fontStyle:'italic'}}><div style={{marginBottom:'6px'}}>{e['tlingit']}</div>{this.state.sortValue === 1 ?<div>{e['english']}</div>:null}</div>
                          } else if (e['type'] === 'subtitle') {
                            return <div style={{fontSize:'16px',fontWeight:'bold',padding:"23px",lineHeight:"22px"}}>{e['english']}</div>
                          } else if (e['type'] === 'break') {
                            return <div style={{height:30}} />
                          }

                        })}
                        </div>
                      
                      :

                        <div style={{margin:'0px',padding:'10px 0px'}}>
                          {dialogues[this.state.chapter]['content'].map((e,eindex)=>{
                              if (e['type'] === 'dia') {
                                return <div style={{margin:20,fontSize:fontSize,lineHeight:'34px'}}>
                                <Grid>
                                <Grid.Row style={{padding:0,margin:0,}} columns={2}>
                                <Grid.Column width={(e['english'].length == 0 ? 0:7)} style={{paddingTop:'6px',paddingLeft:(window.innerWidth < 480 ? '5px':'14px'),paddingRight:'8px'}}>
                                  <div style={{fontSize:'16px',lineHeight:'22px'}}>{e['english']}</div>
                                </Grid.Column>
                                <Grid.Column width={(e['english'].length == 0 ? 11:9)} style={{paddingRight:'5px',paddingLeft:(e['english'].length == 0 ? '14px':'8px')}}>
                                {e['tlingit'].map((f,findex)=> {
                                  return <div style={{wordWrap:'break-word',marginBottom:'5px'}}> 

                                    {f.map((t,tindex)=>{
                                    let entries = [...t[1],...t[2]]
                                    let maxLength = (entries.length % 2 === 0 ? entries.length/2 : (entries.length+1)/2)
                                    if (entries.length === 0) {

                                      return <span>
                                        <span style={{ marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'3px',fontSize:fontSize, borderBottom:'2px dotted white'}}>
                                        {this.processStyledText(t[0])}
                                        </span>
                                        {' '}
                                      </span>

                                    } else {

                                      return <Popup on='click' position={window.innerWidth < innerWidthSetting ? 'bottom right' : 'bottom'}  onClose={()=>{this.setState({parseIndex:0})}} content={
                                        <div style={{fontFamily:customFontFam, fontSize:'16px'}}>
                                          <div style={{textAlign:'center',fontStyle:'italic',color:'#aeaeae',margin:3}}>Possible Entries</div>
                                          {entries.map((k,kindex)=>{
                                            if (kindex === this.state.parseIndex*2 || kindex === this.state.parseIndex*2+1) {
                                            return <div style={{marginTop:15,marginBottom:10}}>
                                                <div style={{display:'flex',alignItems:'center',flexDirection:'row',fontWeight:'500'}}>
                                                  {k['url'].length === 0 ?
                                                    <span style={{whiteSpace:'nowrap'}}>{k['tlingit']}</span>
                                                    :
                                                    <a style={{whiteSpace:'nowrap'}} href={"https://www.haashuka.com/"+k['url']} target="_blank">{k['tlingit']}</a>
                                                  }
                                                  {k['tags'].map((t,tindex)=>
                                                      (tindex === 0 && t.length != 0 ?
                                                      <span style={{display:'flex',marginLeft:'12px'}}>
                                                        <TagColors homeMode={0} tags={t} width={window.innerWidth} />
                                                      </span>
                                                      :
                                                      null
                                                      )
                                                    )}
                                                </div>
                                                <div style={{fontStyle:'italic',marginTop:'5px'}}>{k['english']}</div>
                                            </div>
                                            }})}

                                          {this.state.parseIndex+1 < maxLength+1 && maxLength > 1 ?
                                            <div style={{display:'flex',justifyContent:'center',marginTop:'12px'}}>
                                            <Icon disabled={this.state.parseIndex === 0} circular onClick={() => this.setState({parseIndex: this.state.parseIndex-1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron left' />
                                            <Label basic circular style={{width:30,color:'#aeaeae',fontWeight:500,height:30,fontSize:14,}}>
                                              {this.state.parseIndex + 1}
                                            </Label>
                                            <Icon disabled={this.state.parseIndex+1 === maxLength} circular onClick={() => this.setState({parseIndex: this.state.parseIndex+1})} style={{margin:0,color:'#B1B1B1',cursor:'pointer',fontSize:'15px'}}  name='chevron right' />
                                            </div>  
                                            :
                                            null
                                          }      
                    
                                        </div>
                                      } trigger={
                                        <span>
                                        <span style={{cursor:'pointer', marginBottom:'2px',marginTop:'2px',paddingBottom:'1px',marginRight:'3px',fontSize:fontSize, borderBottom:'2px dotted '+underlineColor}}>
                                          {this.processStyledText(t[0])}
                                        </span>
                                        {' '}
                                        </span>
                                      } />

                                    }


                                  })}

                                    {e['ids'][findex][0] !== "" ? <Icon circular onClick={()=>this.repeatAudio('dialogues-'+e['ids'][findex][0]+'.mp3')} style={{marginRight:'10px',marginLeft:'4px',fontSize:(window.innerWidth < innerWidthSetting ? '14px':'16px'),color:(e['ids'][findex][1] === 'N' ? '#7e6464':'#5b6c85'),cursor:'pointer'}} name='volume up' />:null}


                                    {e['extra'][findex] !== "" ?
                                      <Popup hideOnScroll on='click' content={
                                        <div style={{fontFamily:customFontFam, fontSize:'14px'}}>
                                          <span>{e['extra']}</span>
                                        </div>
                                      } trigger={
                                        <Icon style={{cursor:'pointer',color:'#e5e5e5',fontSize:'18px',marginLeft:'2px'}} name='info circle'>{'\n'}</Icon>
                                      } />
                                      :
                                      null
                                    }

                                  </div>


                                })} 

                                </Grid.Column>
                                </Grid.Row>
                                </Grid>

                                </div>

                              } else if (e['type'] === 'text') {
                                return <div style={{fontSize:'16px',fontStyle:'italic',padding:"25px",textAlign:'center',lineHeight:"22px"}}>{e['english']}</div>
                              } else if (e['type'] === '!ex') {
                                return <Grid>
                                <Grid.Row style={{padding:'10px 0px',margin:0,}} columns={2}>
                                <Grid.Column width={7}>
                                  <div style={{fontSize:'16px',lineHeight:'22px',marginLeft:'40px',fontStyle:'italic'}}>{e['english']}</div>
                                </Grid.Column>
                                <Grid.Column width={9}>
                                  <div style={{fontSize:'16px',lineHeight:'22px',marginLeft:'2px',fontStyle:'italic'}}>{e['tlingit']}</div>
                                </Grid.Column>
                                </Grid.Row>
                                </Grid>
                                // return <div style={{display:'flex',flexDirection:'row',fontSize:'16px',padding:"18px 15px",marginLeft:"24px",lineHeight:"22px"}}><div>{e['english']}</div><div style={{fontStyle:'italic',marginBottom:'6px'}}>{e['tlingit']}</div></div>*/}
                                // return <div><div style={{fontSize:'16px',fontStyle:'italic',padding:"25px 15px",marginLeft:"24px",lineHeight:"22px"}}>{e['tlingit']}</div><div style={{fontSize:'16px',marginLeft:43}}>{e['english']}</div></div>
                              } else if (e['type'] === 'subtitle') {
                                return <div style={{fontSize:'16px',fontWeight:'bold',fontStyle:'italic',padding:"25px 15px",lineHeight:"22px"}}>{e['english']}</div>
                              } else if (e['type'] === 'break') {
                                return <div style={{height:30}} />
                              }
                            
                          })}
                        </div>


                  }


                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        </Grid>


          </Segment>
        </Grid.Column>
        </Grid.Row>
      </Grid>


    
			</div>

		);
	}
}
export default Conversations;
